import MuiCssBaseline from "./MuiCssBaseline";
import MuiTabs from "./MuiTabs";
import MuiTab from "./MuiTab";

const MuiOverrides = {
  MuiCssBaseline,
  MuiTabs,
  MuiTab,
};

export default MuiOverrides;
