import * as React from "react";
import { AppNav } from "../components";
import {
  Box,
  Button,
  Stack,
  Card,
  CardContent,
  Container,
  Typography,
  Link,
  CardMedia,
} from "@mui/material";
import { useWeb3React } from "@web3-react/core";
import { thriftAddress, thriftABI, MAX_ITEMS, getMeta } from "../services/ThriftService";

import { useEffect, useState } from "react";
import { Contract } from "@ethersproject/contracts";
import { injected } from "../services/connectors";

const HomePage = () => {
  const { library, account, activate } = useWeb3React();
  const [totalBags, setTotalBags] = useState(-1);
  const [totalItems, setTotalItems] = useState(-1);
  const [itemsLeft, setItemsLeft] = useState(-1);
  const [canClaim, setCanClaim] = useState<boolean>(false);
  const [bags, setBags] = useState<any[]>([]);

  const getContractData = async () => {
    if (library) {
      const thriftContract = new Contract(thriftAddress, thriftABI, library);
      setCanClaim(await thriftContract.canClaim());
      setTotalBags((await thriftContract.totalBags()).toNumber());
      const items = (await thriftContract.totalItems()).toNumber();
      setTotalItems(items);
      setItemsLeft(MAX_ITEMS - items);
    }
  };

  const getRandomInt = (min: number, max: number): number => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const getRandomBags = async () => {
    const thriftContract = new Contract(thriftAddress, thriftABI, library);
    const list: any[] = [];
    for (let i = 0; i < 3; i++) {
      const meta = getMeta(await thriftContract.uri(getRandomInt(1, totalBags)));
      list.push(meta);
    }
    setBags(list);
  };

  useEffect(() => {
    if (library) {
      library.polling = false;
      getContractData();
    }
  }, [library]);

  useEffect(() => {
    if (totalBags > 0) {
      getRandomBags();
    }
  }, [totalBags]);

  const claimBag = async () => {
    const thriftContract = new Contract(thriftAddress, thriftABI, library);
    const connected = thriftContract.connect(library.getSigner());
    const can = await thriftContract.canClaim();
    if (can) {
      await connected.claim();
    }
    setCanClaim(can);
  };

  return (
    <>
      <AppNav />
      <main>
        <Box sx={{ bgcolor: "background.paper", pt: 8, pb: 6 }}>
          <Container maxWidth="md">
            <Typography
              component="h1"
              variant="h3"
              align="center"
              color="text.primary"
              gutterBottom
            >
              Thriftbags
            </Typography>
            <Typography variant="body1" align="center" color="text.secondary" paragraph>
              Thriftbags and thrift items are uniquely generated and stored as NFT on the Polygon
              chain. <br />
              Check out the pack and unpack feature on the contract and start making your own unique
              combination.
            </Typography>
            {
              <Stack
                spacing={2}
                direction={{ xs: "column", sm: "row" }}
                justifyItems="center"
                justifyContent="center"
              >
                <Card>
                  <CardContent sx={{ textAlign: "center" }}>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                      Thrift left to claim:
                    </Typography>
                    <Typography variant="h5" component="div">
                      {itemsLeft}
                    </Typography>
                  </CardContent>
                </Card>
                <Card>
                  <CardContent sx={{ textAlign: "center" }}>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                      Bags claimed so far:
                    </Typography>
                    <Typography variant="h5" component="div">
                      {totalBags}
                    </Typography>
                  </CardContent>
                </Card>
                <Card>
                  <CardContent sx={{ textAlign: "center" }}>
                    <Typography sx={{ fontSize: 14 }} gutterBottom>
                      Items claimed so far:
                    </Typography>
                    <Typography variant="h5" component="div">
                      {totalItems}
                    </Typography>
                  </CardContent>
                </Card>
              </Stack>
            }
            {canClaim && (
              <Typography variant="subtitle2" align="center" color="text.secondary" component="div">
                {account ? (
                  <>
                    <p>There are still bags to claim, so hurry and hit that button!</p>
                    <Button variant="contained" onClick={claimBag} color="secondary">
                      Claim a bag!
                    </Button>
                  </>
                ) : (
                  <>
                    <p>
                      There are still bags to claim, connect your Polygon account to get started.
                    </p>
                    <Button
                      variant="contained"
                      onClick={() => activate(injected)}
                      color="secondary"
                    >
                      Connect your Polygon account
                    </Button>
                  </>
                )}

                <p>
                  Or check out{" "}
                  <Link href="https://opensea.io/collection/thriftbags" target="_blank">
                    OpenSea.io
                  </Link>{" "}
                  to find something you like.
                </p>
              </Typography>
            )}
            {!canClaim && (
              <Typography variant="subtitle2" align="center" color="text.secondary" component="div">
                <p>Sorry, we are all totally out of bags to claim.</p>
                <p>
                  Check out{" "}
                  <Link href="https://opensea.io/collection/thriftbags" target="_blank">
                    OpenSea.io
                  </Link>{" "}
                  to find something thrift you like.
                </p>
              </Typography>
            )}

            <Typography variant="body2" color="text.secondary" component="div" sx={{ mt: 4 }}>
              Recently claimed Thirftbags.
            </Typography>
            <Stack
              spacing={2}
              direction={{ xs: "column", sm: "row" }}
              justifyItems="center"
              justifyContent="center"
            >
              {bags.map((x, i) => (
                <Card key={i}>
                  <CardContent sx={{ p: 0 }}>
                    <Typography variant="caption" color="text.secondary">
                      {x.name}
                    </Typography>
                  </CardContent>
                  <CardMedia
                    component="img"
                    image={x.image}
                    alt={x.name}
                    sx={{ p: 1, objectFit: "contain" }}
                  />
                </Card>
              ))}
            </Stack>
          </Container>
        </Box>
      </main>
    </>
  );
};

export default HomePage;
