import * as React from "react";
import { AppBar, Toolbar, Typography, Button, Tabs, Tab, Box } from "@mui/material";
import { thriftAddress } from "../../services/ThriftService";
import { ConnectButton } from "..";
import logo from "../../assets/logo.png";

export const AppNav = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <img src={logo} height={48} style={{ marginRight: 8 }} />

        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          <Box sx={{ display: { xs: "none", sm: "block" } }}>Thriftsbags</Box>
        </Typography>
        <Button
          color="inherit"
          href={`https://polygonscan.com/address/${thriftAddress}`}
          target="_blank"
        >
          Contract
        </Button>
        <Button color="inherit">Faq</Button>
        <ConnectButton />
      </Toolbar>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="secondary"
        textColor="inherit"
        variant="fullWidth"
      >
        <Tab label="Claim" />
        <Tab label="Pack" />
        <Tab label="Unpack" />
      </Tabs>
    </AppBar>
  );
};
