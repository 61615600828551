import { createTheme } from "@mui/material/styles";
import components from "./components";

export const theme = createTheme({
  components,
  palette: {
    mode: "light",
    primary: {
      main: "#000000",
    },
    secondary: {
      main: "#f50057",
    },
  },
});
