import { InjectedConnector } from "@web3-react/injected-connector";
import { NetworkConnector } from "@web3-react/network-connector";

const POLLING_INTERVAL = 12000;
const RPC_URLS: { [chainId: number]: string } = {
  137: "https://polygon-mainnet.infura.io/v3/e2a812b7136747d1abfd0c8ef17145b2", // as string,
};

export const injected = new InjectedConnector({ supportedChainIds: [137] });
export const network = new NetworkConnector({
  urls: { 137: RPC_URLS[137] },
  defaultChainId: 137,
});
