import { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { useEagerConnect, useInactiveListener } from "../../hooks/hooks";
import { Jazzicon, jsNumberForAddress } from "..";
import { useWeb3React } from "@web3-react/core";
import { injected, network } from "../../services/connectors";

export const ConnectButton = () => {
  const { account, connector, activate, active } = useWeb3React();

  //handle logic to recognize the connector currently being activated
  const [activatingConnector, setActivatingConnector] = useState<any>();
  useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
  }, [activatingConnector, connector]);

  useEffect(() => {
    activate(network);
  }, []);

  // handle logic to eagerly connect to the injected ethereum provider, if it exists and has granted access already
  const triedEager = useEagerConnect();

  // handle logic to connect in reaction to certain events on the injected ethereum provider, if it exists
  useInactiveListener(!triedEager || !!activatingConnector);

  return (
    <>
      {active && account ? (
        <Jazzicon diameter={32} seed={jsNumberForAddress(account)} />
      ) : (
        <Button color="inherit" onClick={() => activate(injected)}>
          Connect
        </Button>
      )}
    </>
  );
};
