const MuiCssBaseline = {
  styleOverrides: {
    html: {
      padding: 0,
      margin: 0,
    },
    body: {
      padding: 0,
      margin: 0,
    },
  },
};

export default MuiCssBaseline;
