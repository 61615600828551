import React from "react";
import MersenneTwister from "mersenne-twister";

import colors from "./colors";
import Paper from "./Paper";
import { decomposeColor, recomposeColor } from "@mui/system";

const shapeCount = 4;
const svgns = "http://www.w3.org/2000/svg";
const wobble = 30;

interface JazziconProps {
  diameter: number;
  paperStyles?: object;
  seed?: number;
  svgStyles?: object;
}

export const Jazzicon = ({ diameter, paperStyles, seed, svgStyles }: JazziconProps) => {
  const genColor = (colors: string[]) => {
    generator.random();
    const idx = Math.floor(colors.length * generator.random());
    const color = colors.splice(idx, 1)[0];
    return color;
  };

  const rotate = (hex: string, degrees: number): string => {
    const color = decomposeColor(hex);
    color.type = "hsl";

    let hue = color.values[0];
    hue = (hue + degrees) % 360;
    hue = hue < 0 ? 360 + hue : hue;
    color.values[0] = hue;
    color.type = "rgb";
    const s = recomposeColor(color);
    return s;
  };

  const hueShift = (colors: string[], generator: MersenneTwister): string[] => {
    const amount = generator.random() * 30 - wobble / 2;
    return colors.map(hex => {
      const rcol = rotate(hex, amount);
      return rcol;
    });
  };

  const genShape = (remainingColors: string[], diameter: number, i: number, total: number) => {
    const center = diameter / 2;
    const firstRot = generator.random();
    const angle = Math.PI * 2 * firstRot;
    const velocity = (diameter / total) * generator.random() + (i * diameter) / total;
    const tx = Math.cos(angle) * velocity;
    const ty = Math.sin(angle) * velocity;
    const translate = "translate(" + tx + " " + ty + ")";

    // Third random is a shape rotation on top of all of that.
    const secondRot = generator.random();
    const rot = firstRot * 360 + secondRot * 180;
    const rotate = "rotate(" + rot.toFixed(1) + " " + center + " " + center + ")";
    const transform = translate + " " + rotate;
    const fill = genColor(remainingColors);

    return (
      <rect
        key={i}
        x="0"
        y="0"
        rx="0"
        ry="0"
        height={diameter}
        width={diameter}
        transform={transform}
        fill={fill} // todo: make prop
      />
    );
  };

  const generator = new MersenneTwister(seed);
  const remainingColors = hueShift(colors.slice(), generator);
  const shapesArr = Array(shapeCount).fill(0);

  return (
    <Paper color={genColor(remainingColors)} diameter={diameter} style={paperStyles}>
      <svg xmlns={svgns} x="0" y="0" height={diameter} width={diameter} style={svgStyles}>
        {shapesArr.map((s, i) => genShape(remainingColors, diameter, i, shapeCount - 1))}
      </svg>
    </Paper>
  );
};
